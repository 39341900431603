import React, { useEffect, useState } from "react";
import FiltrosRelatorio from "../ui/filtrosRelatorio/FiltrosRelatorio";
import CardRelatorio from "../ui/cardRelatorio/CardRelatorio";

import "./RelatoriosAgendamentos.scss";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import moment from "moment";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

const COLORS = ["#175c93", "crimson", "#55b983"];

function RelatoriosAgendamentos({ auth, clinicaId }) {
  const { sendRequest } = useHttpClient();
  const { startLoading, stopLoading } = useLoading();
  const [agendas, setAgendas] = useState();
  const [compromissos, setCompromissos] = useState();
  const [agendasEmTransicao, setAgendasEmTransicao] = useState();
  const [totalAgendamentos, setTotalAgendamentos] = useState(0);
  const [totalAtendidos, setTotalAtendidos] = useState(0);
  const [taxaComparecimento, setTaxaComparecimento] = useState(0);
  const [totalFaltas, setTotalFaltas] = useState(0);
  const [totalAgendados, setTotalAgendados] = useState(0);
  const [dadosGrafico1, setDadosGrafico1] = useState();
  const [dadosGrafico2, setDadosGrafico2] = useState([
    { name: "Atendido", value: 0 },
    { name: "Faltou", value: 0 },
    { name: "Agendado", value: 0 },
  ]);
  //   const [dataInicial, setDataInicial] = useState(
  //     moment().subtract(1, "months").startOf("day")
  //   );
  //   const [dataFinal, setDataFinal] = useState(moment().endOf("day"));

  useEffect(() => {
    fetchDataByRange(
      moment().subtract(1, "months").startOf("day"),
      moment().endOf("day")
    );
  }, []);

  //   const dateChange = (dataInicial, dataFinal)=>{
  //     setDataInicial(dataInicial)
  //     setDataFinal(dataFinal)
  //   }

  const fetchDataByRange = async (dataInicial, dataFinal) => {
    if (
      moment(dataInicial)
        .startOf("day")
        .isBefore(moment(dataFinal.endOf("day")))
    ) {
      const formData = new FormData();
      formData.append(
        "dataInicial",
        moment(dataInicial).startOf("day").toISOString()
      );
      formData.append(
        "dataFinal",
        moment(dataFinal).endOf("day").toISOString()
      );

      try {
        startLoading();
        const [agendaResponse, agendasEmTransicaoResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/relatorios/${clinicaId}`,
            "PATCH",
            formData,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agendamentosemtransicao/relatorios/${clinicaId}`,
            "PATCH",
            formData,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        let tempTotalAgendamentos = 0;
        let tempTotalAtendidos = 0;
        let tempTotalFaltas = 0;
        let tempTaxaComparecimento = 0;
        let tempAgendados = 0;

        agendaResponse.agendas.forEach((agenda) => {
          tempTotalAgendamentos += agenda.marcacoes.length;
          agenda.marcacoes.forEach((m) => {
            if (m.atendido) tempTotalAtendidos += 1;
            if (m.faltou) tempTotalFaltas += 1;
            if (!m.faltou && !m.atendido) tempAgendados += 1;
          });
        });

        agendasEmTransicaoResponse.agendas.forEach((agenda) => {
          tempTotalAgendamentos += agenda.marcacoes.length;
          agenda.marcacoes.forEach((m) => {
            if (m.atendido) tempTotalAtendidos += 1;
            if (m.faltou) tempTotalFaltas += 1;
            if (!m.faltou && !m.atendido) tempAgendados += 1;
          });
        });

        tempTaxaComparecimento =
          tempTotalAtendidos + tempTotalFaltas === 0
            ? 0
            : (
                (tempTotalAtendidos / (tempTotalAtendidos + tempTotalFaltas)) *
                100
              ).toFixed(2);

        setTotalAgendamentos(tempTotalAgendamentos);
        setTotalAtendidos(tempTotalAtendidos);
        setTotalFaltas(tempTotalFaltas);
        setTaxaComparecimento(tempTaxaComparecimento);
        setTotalAgendados(tempAgendados);

        setAgendas(agendaResponse.agendas);
        //   setCompromissos(compromissosResponse.compromisso);
        setAgendasEmTransicao(agendasEmTransicaoResponse.agendas);
        setDadosGrafico2([
          { name: "Atendido", value: tempTotalAtendidos },
          { name: "Faltou", value: tempTotalFaltas },
          { name: "Agendado", value: tempAgendados },
        ]);

        const objectoGrafico = processDoctorPerformanceData(
          agendaResponse.agendas,
          agendasEmTransicaoResponse.agendas
        );

        setDadosGrafico1(objectoGrafico);
      } catch (err) {
        toast.error(
          "Erro na busca dos agendamentos. Por favor tente novamente fazendo refresh na página."
        );
        console.error(err);
      } finally {
        stopLoading();
      }
    }
  };

  function processDoctorPerformanceData(tempAgenda, tempAgendaEmTransicao) {
    const doctorPerformance = {};
    try {
      // Process regular tempAgenda
      tempAgenda.forEach((agenda) => {
        const doctorId = agenda.medico._id;
        const doctorName = agenda.medico.name; // Assuming the name is populated

        if (!doctorPerformance[doctorId]) {
          doctorPerformance[doctorId] = {
            name: doctorName,
            Procedimento: 0,
            Avaliação: 0,
            EmTransição: 0,
          };
        }

        agenda.marcacoes.forEach((marcacao) => {
          if (marcacao.avaliacao) {
            doctorPerformance[doctorId].Avaliação++;
          } else {
            doctorPerformance[doctorId].Procedimento++;
          }
        });
      });

      // Process tempAgenda in transition
      tempAgendaEmTransicao.forEach((agenda) => {
        const doctorId = agenda.medico._id;
        const doctorName = agenda.medico.name; // Assuming the name is populated

        if (!doctorPerformance[doctorId]) {
          doctorPerformance[doctorId] = {
            name: doctorName,
            Procedimento: 0,
            Avaliação: 0,
            EmTransição: 0,
          };
        }

        agenda.marcacoes.forEach(() => {
          doctorPerformance[doctorId].EmTransição++;
        });
      });
    } catch (err) {
      console.log("err", err);
    }
    // Convert the object to an array
    return Object.values(doctorPerformance);
  }

  return (
    <>
      <FiltrosRelatorio fetchDataByRange={fetchDataByRange} />
      <div className="relatorio__cardContainer">
        <CardRelatorio
          titulo="Total de Agendamentos"
          valor={totalAgendamentos}
        />
        <CardRelatorio titulo="Total por Atender" valor={totalAgendados} />
        <CardRelatorio titulo="Total de Atendidos" valor={totalAtendidos} />
        <CardRelatorio titulo="Total de Faltas" valor={totalFaltas} />
        <CardRelatorio
          titulo="Taxa de Comparecimento"
          valor={taxaComparecimento + "%"}
        />
      </div>

      <div className="relatorio__grapfsContainer">
        <div className="relatorio__grapfsContainer__item relatorio__grapfsContainer__item--bar">
          <span className="relatorio__grapfsContainer__item__title">
            Agendamentos por Dentista e Tipo
          </span>
          <div className="chart-wrapper">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={dadosGrafico1}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Procedimento" stackId="a" fill="#175c93" />
                <Bar dataKey="Avaliação" stackId="a" fill="#ffc658" />
                <Bar dataKey="EmTransição" stackId="a" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="relatorio__grapfsContainer__item relatorio__grapfsContainer__item--pie">
          <span className="relatorio__grapfsContainer__item__title">
            Distribuição de situação de Agendamento
          </span>
          <div className="chart-wrapper">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={dadosGrafico2}
                  // cx="50%"
                  // cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {dadosGrafico2.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default RelatoriosAgendamentos;
