import React, { useEffect, useState } from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import "./FiltrosRelatorio.scss";

function FiltrosRelatorio({ fetchDataByRange }) {
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(1, "months").startOf("day")
  );
  const [dataFinal, setDataFinal] = useState(moment().endOf("day"));
  const [openModalFiltros, setOpenModalFiltros] = useState(false);

  const dateChange = (data, tipo) => {
    if (tipo === "inicial") {
      setDataInicial(data);
      fetchDataByRange(data, dataFinal);
    } else {
      setDataFinal(data);
      fetchDataByRange(dataInicial, data);
    }
  };
  return (
    <div className="relatorio__filtros__container">
      <div className="relatorio__filtros__container__left">
        <span className="relatorio__filtros__texto">Periodo</span>
        <div className="financeiro__filtros__periodoContainer">
          <div className="financeiro__filtros__periodoContainer__dataInicioContainer financeiro__filtros__periodoContainer__dataContainer">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Inicial*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => dateChange(value, "inicial")}
                value={dataInicial}
              />
            </LocalizationProvider>
          </div>
          <div className="financeiro__filtros__periodoContainer__dataFimContainer financeiro__filtros__periodoContainer__dataContainer">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Final*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => dateChange(value, "final")}
                value={dataFinal}
              />
            </LocalizationProvider>
          </div>
        </div>

        {/* 
          <div
            className={`financeiro__filtros__filtrar ${
              (filtrosAplicados && filtrosAplicados.length > 0) ||
              (filtroCaixa && filtroCaixa !== "todas") ||
              (filtroCategoria && filtroCategoria !== "todas") ||
              (filtroMetodoPagamento && filtroMetodoPagamento !== "todas")
                ? "financeiro__filtros__filtrar--ativo"
                : ""
            }`}
            onClick={(e) => setOpenModalFiltros(true)}
          >
            <FilterAltIcon
              className={
                (filtrosAplicados && filtrosAplicados.length > 0) ||
                (filtroCaixa && filtroCaixa !== "todas") ||
                (filtroCategoria && filtroCategoria !== "todas") ||
                (filtroMetodoPagamento && filtroMetodoPagamento !== "todas")
                  ? "filtro__ativo financeiro__filtros__icon"
                  : "financeiro__filtros__icon"
              }
            />
            <span
              className={
                (filtrosAplicados && filtrosAplicados.length > 0) ||
                (filtroCaixa && filtroCaixa !== "todas") ||
                (filtroCategoria && filtroCategoria !== "todas") ||
                (filtroMetodoPagamento && filtroMetodoPagamento !== "todas")
                  ? "filtro__ativo financeiro__filtros__icon-text"
                  : "financeiro__filtros__icon-text"
              }
            >
              Filtrar
            </span>
          </div> */}
      </div>
      <div className="relatorio__filtros__container__right">
        <span className="relatorio__filtros__container__right__btn relatorio__filtros__container__right__btn--blue">
          Exportar Excel
        </span>
        <span className="relatorio__filtros__container__right__btn relatorio__filtros__container__right__btn--green">
          Gerar Relatório
        </span>
      </div>
    </div>
  );
}

export default FiltrosRelatorio;
