import React from "react";
import "./CardRelatorio.scss";

function CardRelatorio({ titulo, valor }) {
  return (
    <div className="cardRelatorio">
      <span className="cardRelatorio__titulo">{titulo}</span>
      <span className="cardRelatorio__valor">{valor}</span>
    </div>
  );
}

export default CardRelatorio;
